<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select v-model="query.agentId" @change="agentChange" clearable filterable placeholder="请选择代理商"
					size="small" class="handle-input mr10">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select v-model="query.companyId" clearable filterable placeholder="请选择企业" size="small"
					class="handle-input mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="getData">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]"
					@click="getCompanySettingsQuery">设置参数</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="showForm">添加模版</el-button>
			</div>
			<el-row :gutter="20" type="flex" class="row-bg">
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" height="500">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="content" label="内容" align="center" width="300"></el-table-column>
						<el-table-column prop="name1" label="变量一" align="center"></el-table-column>
						<el-table-column prop="name2" label="变量二" align="center"></el-table-column>
						<el-table-column prop="name3" label="变量三" align="center"></el-table-column>
						<el-table-column prop="name4" label="变量四" align="center"></el-table-column>
						<el-table-column prop="name5" label="变量五" align="center"></el-table-column>
						<el-table-column prop="templateId" label="模版ID" align="center"></el-table-column>
						<el-table-column prop="templateName" label="模版名称" align="center"></el-table-column>
						<el-table-column label="操作" align="center">
							<template #default="scope">
								<el-button size="small" type="primary" :class="[theme]"
									@click="handleUpdate(scope.row)">编辑</el-button>
								<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
									@click="handleDelete(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</div>
		<el-dialog title="编辑" v-model="updateVisible" width="500px" :close-on-click-modal="false">
			<el-form :model="updateForm" ref="updateForm" label-width="120px">
				<el-form-item label="企业" prop="companyId">
					<el-select v-model="updateForm.companyId" clearable filterable placeholder="请选择企业" size="small"
						style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="模版ID">
					<el-input size="small" placeholder="请填写模版ID" v-model="updateForm.templateId"></el-input>
				</el-form-item>
				<el-form-item label="模版名称">
					<el-input size="small" placeholder="请填写模版名称" v-model="updateForm.templateName"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input size="small" type="textarea" :rows="5" placeholder="请输入内容" v-model="updateForm.content"
						@change="isContent2"></el-input>
				</el-form-item>
				<el-form-item label="变量一名称" v-if="count2>=1">
					<el-input size="small" placeholder="请填写变量一名称" v-model="updateForm.name1"></el-input>
				</el-form-item>
				<el-form-item label="变量二名称" v-if="count2>=2">
					<el-input size="small" placeholder="请填写变量二名称" v-model="updateForm.name2"></el-input>
				</el-form-item>
				<el-form-item label="变量三名称" v-if="count2>=3">
					<el-input size="small" placeholder="请填写变量三名称" v-model="updateForm.name3"></el-input>
				</el-form-item>
				<el-form-item label="变量四名称" v-if="count2>=4">
					<el-input size="small" placeholder="请填写变量四名称" v-model="updateForm.name4"></el-input>
				</el-form-item>
				<el-form-item label="变量五名称" v-if="count2>=5">
					<el-input size="small" placeholder="请填写变量五名称" v-model="updateForm.name5"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="updateVisibleClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getTemUpdate">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="手动添加" v-model="addVisible" width="500px" :close-on-click-modal="false">
			<el-form :model="addForm" ref="addForm" label-width="120px">
				<el-form-item label="代理" prop="agent">
					<el-select v-model="addForm.agentId" @change="agentChange" clearable filterable placeholder="请选择代理商"
						size="small" style="width: 100%;">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId">
					<el-select v-model="addForm.companyId" clearable filterable placeholder="请选择企业" size="small"
						style="width: 100%;">
						<el-option v-for="item in companys" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="模版ID">
					<el-input size="small" placeholder="请填写模版ID" v-model="addForm.templateId"></el-input>
				</el-form-item>
				<el-form-item label="模版名称">
					<el-input size="small" placeholder="请填写模版名称" v-model="addForm.templateName"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input size="small" type="textarea" :rows="5" placeholder="请输入内容" v-model="addForm.content"
						@change="isContent"></el-input>
				</el-form-item>
				<el-form-item label="变量一名称" v-if="count>=1">
					<el-input size="small" placeholder="请填写变量一名称" v-model="addForm.name1"></el-input>
				</el-form-item>
				<el-form-item label="变量二名称" v-if="count>=2">
					<el-input size="small" placeholder="请填写变量二名称" v-model="addForm.name2"></el-input>
				</el-form-item>
				<el-form-item label="变量三名称" v-if="count>=3">
					<el-input size="small" placeholder="请填写变量三名称" v-model="addForm.name3"></el-input>
				</el-form-item>
				<el-form-item label="变量四名称" v-if="count>=4">
					<el-input size="small" placeholder="请填写变量四名称" v-model="addForm.name4"></el-input>
				</el-form-item>
				<el-form-item label="变量五名称" v-if="count>=5">
					<el-input size="small" placeholder="请填写变量五名称" v-model="addForm.name5"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="addVisibleClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getTemAdd">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="设置参数" v-model="setVisible" width="500px" :close-on-click-modal="false">
			<el-form :model="setForm" ref="setForm" label-width="120px">
				<el-form-item label="客户ID">
					<el-input size="small" placeholder="请填写客户ID" v-model="setForm.custId"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="handleClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getCompanySettings">确认</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	import { fetchCompanyByAgentId } from '../api/companyIndex.js';
	import { fetchAgent } from '../api/agentIndex';
	import { companySettings, temAList, temAdd, temUpdate, temDelete, companySettingsQuery } from '../api/textmessage.js';
	export default {
		name: 'outboundRecord',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
				},
				tableData: [],
				pageTotal: 0,
				count: 0,
				count2: 0,
				multipleSelection: [],
				addForm: {
					companyId: '',
					content: '',
					name1: '',
					name2: '',
					name3: '',
					name4: '',
					name5: '',
				},
				setForm: {
					custId: '',
				},
				setVisible: false,
				addVisible: false,
				agents: [],
				companys: [],
				updateVisible: false,
				updateForm: {
					companyId: '',
					content: '',
					name1: '',
					name2: '',
					name3: '',
					name4: '',
					name5: '',
				}
			};
		},
		created() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理商
				this.agents = res.data;
			});
		},
		methods: {
			//获取代理商
			agentChange(agentId) {
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				let data = {
					companyId: this.query.companyId
				}
				temAList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//设置企业短信参数
			getCompanySettings() {
				let data = {
					companyId: this.query.companyId,
					custId: this.setForm.custId
				}
				companySettings(data).then(res => {
					if (res.code == 200) {
						this.setVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			updateVisibleClose() {
				this.updateVisible = false;
			},
			//编辑
			getTemUpdate() {
				temUpdate(this.updateForm).then(res => {
					if (res.code == 200) {
						this.updateVisible = false;
						this.$message.success(res.message);
						this.query.agentId = this.updateForm.agentId;
						this.query.companyId = this.updateForm.companyId;
						this.getData()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//编辑弹窗
			handleUpdate(row) {
				this.updateVisible = true;
				this.updateForm = row;
				this.isContent2();
			},
			//删除
			handleDelete(row) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							companyId: row.companyId,
							templateId: row.templateId
						};
						temDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			//添加
			getTemAdd() {
				temAdd(this.addForm).then(res => {
					if (res.code == 200) {
						this.addVisible = false;
						this.query.agentId = this.addForm.agentId;
						this.query.companyId = this.addForm.companyId;
						this.getData()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//添加弹窗
			showForm() {
				this.addForm = {
					companyId: '',
					content: '',
					name1: '',
					name2: '',
					name3: '',
					name4: '',
					name5: '',
				};
				this.addVisible = true;
			},
			//添加的{$}判断
			isContent() {
				let matches = this.addForm.content.match(/\{\$\}/g);
				this.count = matches ? matches.length : 0;
			},
			//编辑的{$}判断
			isContent2() {
				let matches = this.updateForm.content.match(/\{\$\}/g);
				this.count2 = matches ? matches.length : 0;
			},
			//查询企业短信参数
			getCompanySettingsQuery() {
				let data = {
					companyId: this.query.companyId
				}
				companySettingsQuery(data).then(res => {
					if (res.code == 200) {
						this.setVisible = true;
						this.setForm.custId = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//弹窗
			showExpressDialog(row) {
				this.dialogVisible = true;
				let data = {
					id: row.id,
				}
				logisticsInquiry(data).then(res => {
					if (res.code == 200) {
						this.expressData = res.data;
						this.expressData.userData = row;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			addVisibleClose() {
				this.addVisible = false;
			},
			handleClose() {
				this.setVisible = false;
				this.setForm.custId = '';
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			//下一页
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.w_130 {
		width: 130px;
	}
</style>