<template>
	<div>
		<div class="container">

			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable placeholder="请输入号码" class="mr10"
					style="width: 180px;"></el-input>
				<el-button size="small" type="primary" :class="[theme]" @click="getdeptParam">查询</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getImport">导入号码</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="batchDelete">批量删除</el-button>
				<!-- <a :href="`${path}dxtQuery.txt`" class="down" style="margin:10px 10px;" download="批量查询模板.txt"
							:class="[theme]">
							<i class="el-icon-download"></i>
							下载模板
						</a> -->
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="paramValue" label="手机号码" align="center">
					<template #default="scope">
						{{ scope.row.paramValue.split('-')[0] }}
					</template>
				</el-table-column>
				<el-table-column prop="paramValue" label="呼叫频次" align="center">
					<template #default="scope">
						{{ scope.row.paramValue.split('-')[1] }}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="240" align="center">
					<template #default="scope">
						<el-button size="small" type="danger" :class="[theme]"
							@click="batchDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100, 300]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
			<el-dialog title="导入号码" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="addForm">
					<el-form-item label="频次" v-if="batchDeleteDowm">
						<el-select size="small" v-model="addForm.succeed" filterable clearable placeholder="请选择频次"
							style="width: 100%">
							<el-option v-for="item in succeedList" :key="item" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="号码">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码"
							v-model="addForm.phones"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAddFrequencyLimit"
							v-if="batchDeleteDowm">确定</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="batchDelete2"
							v-else>批量删除</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import {
		deptParam,
		AddFrequencyLimit,
		deleteBatch
	} from '../api/wn.js';
	export default {
		name: 'frequencyLimit',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					userId: localStorage.getItem('user'),
					phoneNumber: '',
					pageIndex: 1,
					pageSize: 30,
				},
				batchDeleteDowm: false,
				uploadData: {
					userId: localStorage.getItem('user')
				},
				addVisible: false,
				tableData: [],
				multipleSelection: [],
				paramType: '',
				succeedList: [20, 40, 80, 100, 150, 200, 300],
				multipleSelectionStr: '',
				pageTotal: 0,
				token: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				path: process.env.BASE_URL,
				addForm: {
					userId: localStorage.getItem('user'),
					succeed: '',
					phones: ''
				},

			};
		},

		created() {
			this.getdeptParam();
		},

		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			getdeptParam() {
				let data = {
					phoneNumber: this.query.phoneNumber,
					userId: this.query.userId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
				}
				deptParam(data).then(res => {
					if (res.code == 200) {
						if (res.data.list) {
							this.tableData = res.data.list;
							this.pageTotal = res.data.total;
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},

			getAddFrequencyLimit() {
				AddFrequencyLimit(this.addForm).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addVisible = false;
						this.addForm.phones = '';
						this.getdeptParam();
					} else {
						this.$message.error(res.message);
					}
				});
			},

			closeDialog() {
				this.addVisible = false;
				this.addForm.phones = '';
				this.batchDeleteDowm = false;
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;

			},
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getdeptParam();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getdeptParam();
			},
			getImport() {
				this.addVisible = true;
				this.batchDeleteDowm = true;
				this.addForm.succeed = '';
				this.addForm.phones = '';
			},
			batchDelete(row) {
				if (row.paramType) {
					this.$confirm('是否要删除此号码的拨打频次？', '提示', {
							type: 'warning',
							confirmButtonText: '删除',
							cancelButtonText: '不删除',
							closeOnClickModal: false
						})
						.then(() => {
							let data = {
								userId: localStorage.getItem('user'),
								id: row.seqId,
								paramType: row.paramType
							}
							deleteBatch(data).then(res => {
								if (res.code == 200) {
									this.$message.success(res.data);
									this.getdeptParam();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {

						});
				} else {
					for (let i = 0; i < this.multipleSelection.length; i++) {
						this.multipleSelectionStr += this.multipleSelection[i].seqId + ',';
						this.paramType = this.multipleSelection[0].paramType;
					}
					if (this.multipleSelectionStr === '') {
						this.addVisible = true;
					} else {
						this.$confirm('是否要批量删除此号码的拨打频次？', '提示', {
								type: 'warning',
								confirmButtonText: '删除',
								cancelButtonText: '不删除',
								closeOnClickModal: false
							})
							.then(() => {
								let data = {
									userId: localStorage.getItem('user'),
									id: this.multipleSelectionStr,
									paramType: this.paramType
								}
								console.log(data, '大阿福但是')
								deleteBatch(data).then(res => {
									if (res.code == 200) {
										this.$message.success(res.data);
										this.getdeptParam();
									} else {
										this.$message.error(res.message);
									}
								});
							})
							.catch(() => {

							});
					}
				}
			},
			batchDelete2() {
				this.$confirm('是否要批量删除此号码的拨打频次？', '提示', {
						type: 'warning',
						confirmButtonText: '删除',
						cancelButtonText: '不删除',
						closeOnClickModal: false
					})
					.then(() => {
						let data = {
							userId: localStorage.getItem('user'),
							phones: this.addForm.phones,
						}
						deleteBatch(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.addVisible = false;
								this.addForm.phones = ''
								this.getdeptParam();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {

					});
			}

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		/* width: 1700px; */
		/* border: 1px solid #ebeef5; */
		display: flex;
		flex-direction: row;
		margin-bottom: 40px;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 600px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		border-left: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: #226003;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-block;
	}
</style>