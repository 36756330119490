<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.cardsId" clearable filterable placeholder="请选择品牌" class="mr10"
					style="width: 140px;">
					<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
						:value="item.label"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" class="handle-add"
					@click="getData">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="handle-add mr100" @click="showForm"
					v-if="isAdmin">添加品牌</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column prop="cardType" label="品牌" align="center"></el-table-column>
				<el-table-column prop="ip" label="IP地址" align="center"></el-table-column>
				<el-table-column prop="appPort" label="移动端口" align="center"></el-table-column>
				<el-table-column prop="fsUrl" label="SSL域名" align="center"></el-table-column>
				<el-table-column prop="sslPort" label="SSL端口" align="center"></el-table-column>
				<el-table-column prop="prefix" label="前缀" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center" v-if="isAdmin">
					<template #default="scope">
						<el-button size="small" type="text" @click="editHandle(scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="添加品牌" v-model="addVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="品牌" prop="cardsId">
					<el-select size="small" v-model="form.cardsId" clearable filterable placeholder="请选择品牌"
						style="width: 100%;">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="IP地址" prop="ip">
					<el-input size="small" v-model="form.ip" placeholder="请输入IP地址"></el-input>
				</el-form-item>
				<el-form-item label="移动端口" prop="appPort">
					<el-input size="small" v-model="form.appPort" placeholder="请输入移动端口号"></el-input>
				</el-form-item>
				<el-form-item label="SSL域名" prop="fsUrl">
					<el-input size="small" v-model="form.fsUrl" placeholder="请输入SSL域名"></el-input>
				</el-form-item>
				<el-form-item label="SSL端口" prop="sslPort">
					<el-input size="small" v-model="form.sslPort" placeholder="请输入SSL端口号"></el-input>
				</el-form-item>

				<el-form-item label="前缀" prop="prefix">
					<el-input size="small" v-model="form.prefix" placeholder="请输入端口前缀"></el-input>
				</el-form-item>

			</el-form>
			<div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getserverAdd('form')">确定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="编辑品牌" v-model="editVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="editForm" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="IP地址" prop="ip">
					<el-input size="small" v-model="editForm.ip" placeholder="请输入IP地址"></el-input>
				</el-form-item>
				<el-form-item label="移动端口" prop="appPort">
					<el-input size="small" v-model="editForm.appPort" placeholder="请输入移动端口号"></el-input>
				</el-form-item>
				<el-form-item label="SSL域名" prop="fsUrl">
					<el-input size="small" v-model="editForm.fsUrl" placeholder="请输入SSL域名"></el-input>
				</el-form-item>
				<el-form-item label="SSL端口" prop="sslPort">
					<el-input size="small" v-model="editForm.sslPort" placeholder="请输入SSL端口号"></el-input>
				</el-form-item>

				<el-form-item label="前缀" prop="prefix">
					<el-input size="small" v-model="editForm.prefix" placeholder="请输入端口前缀"></el-input>
				</el-form-item>

			</el-form>
			<div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getserverUpdate('form')">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { serverList, serverAdd, serverUpdate, serverDelete } from '../api/setMeal.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'cardType',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				pageTotal: 0,
				addVisible: false,
				form: {
					cardsId: '',
					ip: '',
					sslPort: '',
					appPort: '',
					prefix: '',
					fsUrl: ''
				},
				cardTypeList: [],
				loading: false,
				isAdmin: false,
				editVisible: false,
				editForm: {
					prefix: '',
					sslPort: '',
					appPort: '',
					ip: '',
					id: '',
					fsUrl: ''
				},
				query: {
					cardsId: '',
					pageIndex: 1,
					pageSize: 30
				}
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			if (role === 'admin') {
				this.isAdmin = true;
			}
			this.getCardType();
			this.getData();
		},
		methods: {
			//获取品牌
			getCardType() {
				getCardType('').then(res => {
					this.cardTypeList = res.data;
				})
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				serverList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
			},
			getserverAdd(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						serverAdd(this.form).then(res => {
							if (res.code == 200) {
								this.$message.success('添加成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			//编辑显示
			editHandle(row) {
				this.editVisible = true;
				this.editForm = row;
			},
			getserverUpdate() {
				serverUpdate(this.editForm).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.editVisible = false;
						this.editForm = {};
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id,
						};
						serverDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.addVisible = false;
				this.editVisible = false;
				(this.form = {
					cardsId: '',
					ip: '',
					port: '',
					prefix: '',
					sslPort: '',
					appPort: '',

				}),
				this.resetForm(formName);
				(this.editForm = {
					cardsId: '',
					ip: '',
					id: '',
					prefix: '',
					sslPort: '',
					appPort: '',

				}),
				this.resetForm(formName);
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 40px;
	}

	/* 
a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>