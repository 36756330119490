<template>
	<div class="contacts-box">
		<!-- <div class="header-box">
			<div class="nav">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="实体卡" name="physicalCard">实体卡</el-tab-pane>
					<el-tab-pane label="SIP小号" name="SIP">SIP小号</el-tab-pane>
					<el-tab-pane label="特服号" name="specialServiceNumber">特服号</el-tab-pane>
				</el-tabs>
			</div>
			<div class="title">
				<i class="el-icon-office-building"></i>
				销售数据在线看板
			</div>
			<div class="time">
				<el-date-picker v-model="value1" type="daterange" range-separator="——" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div> -->
		<div class="inventory-box">
			<el-row :gutter="40">
				<el-col :span="18">
					<div class="content-left">
						<div class="bor-fff">
							<div class="header-tit">
								<div class="left">
									<span class="sign"></span>
									<span class="tit">号码状态统计</span>
								</div>
								<div class="right">
									<el-date-picker v-model="value1" type="daterange" range-separator="—"
										start-placeholder="开始日期" end-placeholder="结束日期" size="small">
									</el-date-picker>
									<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
								</div>
							</div>
							<div class="list-box">
								<ul>
									<li class="bg_ecf1cf">
										<div>在网号码数量</div>
										<span>5698</span>
									</li>
									<li class="bg_dbfbf8">
										<div>销售号码数量</div>
										<span>5698</span>
									</li>
									<li class="bg_fbeffd">
										<div>已激活号码数量</div>
										<span>5698</span>
									</li>
									<li class="bg_aceffd">
										<div>品牌分类</div>
										<span>5698</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="bor-fff">
							<div class="header-tit">
								<div class="left">
									<span class="sign"></span>
									<span class="tit">客户数据统计</span>
								</div>
								<div class="right">
									<el-date-picker v-model="value1" type="daterange" range-separator="—"
										start-placeholder="开始日期" end-placeholder="结束日期" size="small">
									</el-date-picker>
									<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
								</div>
							</div>
							<div class="list-box">
								<ul>
									<li class="bg_ecf1cf">
										<div>投诉数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>新客户数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>品牌分类</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>投诉数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>新客户数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>数量</div>
										<span>5698</span>
									</li>
									<li class="bg_ecf1cf">
										<div>品牌分类</div>
										<span>5698</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="header-tit">
							<div class="left">
								<span class="sign"></span>
								<span class="tit">销售进度统计</span>
							</div>
							<div class="right">
								<i class="el-icon-refresh-right"
									style="font-size: 22px;color:#2799fc;margin-right: 6px;position: relative;top: 3px;cursor: pointer;"></i>
								<span class="tit">截止当前时间</span>
							</div>
						</div>
						<div class="sales-statistics-box">
							<div class="price-box">
								<span>4111111.17</span>
								<span>元</span>
							</div>
							<div class="data-box">
								<div>
									<p>同比</p>
									<span><i class="el-icon-top" v-if="a"></i>
										<i class="el-icon-bottom" style="color: red;"></i>200%</span>
								</div>
								<div>
									<p>同期</p><span>200000元</span>
								</div>
							</div>
						</div>
						<div class="sales-statistics-tit">
							<span>销售回款进度</span>
							<i class="el-icon-d-arrow-right"
								style="font-size: 14px;color:#2799fc;margin-left: 6px;"></i>
						</div>
						<div class="user-data-box">
							<div class="user-data-list">
								<span>小明</span>
								<el-progress :percentage=" 20" :color="gradientColor"></el-progress>
							</div>
							<div class="user-data-list">
								<span>小明小明</span>
								<el-progress :percentage=" 20" :color="gradientColor"></el-progress>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="40">
				<el-col :span="18">
					<div class="content-left">
						<div class="bor-fff">
							<div class="header-tit">
								<div class="left">
									<span class="sign"></span>
									<span class="tit">公司产品销售趋势</span>
								</div>
								<div class="right">
									<el-button size="small" type="primary" :class="[theme]" @click="">导出表格</el-button>
								</div>
							</div>
							<el-card shadow="hover">
								<div id="columnMain" ref="columnMain" style="height: 450px;width: 100%;"></div>
							</el-card>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="content-right">
						<div class="header-tit">
							<div class="left">
								<span class="sign"></span>
								<span class="tit">客户分类号码占比统计</span>
							</div>
						</div>
						<el-card shadow="hover">
							<div id="ring" ref="ring" style="height: 250px;width: 100%;"></div>
						</el-card>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import { fetchAgent, updateAfterSale } from '../api/inventory';
	import {
		getMonthInfo,
	} from '../api/sumIndex.js';
	export default {
		name: 'SJTXinventory',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				activeName: 'physicalCard',
				gradientColor: 'linear-gradient(to right, #ff7f50, #ffa500)',
				myChart: null,
				myChart2: null
			};
		},
		beforeUnmount() {
			if (!this.myChart) {
				return;
			} else {
				this.myChart.dispose();
				this.myChart = null;
			}
			if (!this.myChart2) {
				return;
			} else {
				this.myChart2.dispose();
				this.myChart2 = null;
			}
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.getCanvas();
			this.getCanvas2();
		},
		methods: {
			getCanvas() {
				let echarts = require('echarts');
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getMonthInfo(data).then(res => {
					localStorage.setItem('echartsData', JSON.stringify(res.data));
					let data = res.data;
					let option = {
						legend: {
							tooltip: {
								show: true,
								color: 'red'
							},
							data: [{
									name: '拨打次数',
									icon: 'circle'
								},
								{
									name: '接通次数',
									icon: 'circle'
								},
								{
									name: '通话分钟数',
									icon: 'circle'
								}
							]
						},
						tooltip: {
							padding: 5,
							textStyle: {
								color: '#000' //---提示框内容样式
							}
						},
						grid: {
							top: 50, //---相对位置，top\bottom\left\right
							bottom: 20,
							left: 20,
							right: 20,
							containLabel: true //---grid 区域是否包含坐标轴的刻度标签
						},
						xAxis: {
							data: data.date //内容
						},
						yAxis: {
							axisLine: {
								show: true //---是否显示
							}
						},
						series: [{
								name: data.datasets[0].label,
								type: 'bar', //---类型
								legendHoverLink: true, //---是否启用图例 hover 时的联动高亮

								itemStyle: {
									//---图形形状
									color: '#5ea4ec',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10', //---柱形宽度
								barCategoryGap: '10',
								barGap: this.winWidth, //---柱形间距
								data: data.datasets[0].data
							},
							{
								name: data.datasets[1].label,
								type: 'bar',
								legendHoverLink: true,

								itemStyle: {
									color: '#22d422',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[1].data
							},
							{
								name: data.datasets[2].label,
								type: 'bar',
								legendHoverLink: true,
								itemStyle: {
									color: '#E6A23C',
									borderRadius: [10, 10, 0, 0]
								},
								barWidth: '10',
								barCategoryGap: '10',
								barGap: this.winWidth,
								data: data.datasets[2].data
							}
						]
					};
					this.myChart = echarts.init(this.$refs.columnMain);
					this.myChart.setOption(option);
				});
			},
			getCanvas2() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				getMonthInfo(data).then(res => {
					localStorage.setItem('echartsData2', JSON.stringify(res.data));
					let echarts = require('echarts');
					let aa = {
						0: 10,
						1: 20
					}
					let oper_y = Object.values(aa);
					let oper_x = ['客户分类', '号码数量'];
					let chartdata = [];
					oper_x.forEach((one, index) => {
						let item = {};
						item.name = one;
						item.value = oper_y[index];
						chartdata.push(item);
					});
					console.log(this.$refs, 'this.$refs')
					this.myChart2 = echarts.init(this.$refs.ring);
					let option = {
						legend: {
							orient: 'horizontal',
							x: 'center', //可设定图例在左、右、居中
							y: 'bottom',
							data: ['意向强', '意向一般']
						},
						tooltip: {
							trigger: 'item',
							show: true,
							formatter: '{c}%'
						},
						series: [{
							type: 'pie',
							center: ['50%', '40%'],
							radius: ['35%', '55%'], //环形
							data: chartdata,
							minAngle: 10
						}]
					};
					this.myChart2.setOption(option);
				});
			},
		}
	};
</script>

<style scoped>
	.contacts-box {
		.header-box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.inventory-box {
			.bor-fff {
				border: 2px solid #fff;
				padding: 11px 22px;
				border-radius: 10px;
				background-color: #e0eafe;
				margin-bottom: 20px;
				box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);
				/*多层阴影*/
			}

			.content-left {
				.header-tit {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					color: #353535;
					font-size: 24px;
					padding-bottom: 10px;
					border-bottom: 2px solid #fff;

					.left {
						.sign {
							display: inline-block;
							background-color: #2799fc;
							width: 6px;
							height: 6px;
							border-radius: 50%;
							margin-right: 10px;
						}

						.tit {
							font-size: 16px;
							color: #000;
						}
					}

					.right {
						.el-date-editor--daterange {
							width: 100px !important;
						}

						.el-button {
							margin-left: 6px;
						}
					}

				}

				.list-box {
					ul {
						display: flex;
						flex-wrap: wrap;
						list-style: none;
						padding: 0;
						margin: 0;

						li {
							flex-basis: 23%;
							box-sizing: border-box;
							padding: 10px;
							margin: 10px 1%;
							height: 100px;
							text-align: left;

							div {
								line-height: 30px;
								font-size: 16px;
							}

							span {
								display: inline-block;
								line-height: 50px;
								font-weight: 600;
								font-size: 28px;
								color: #fd661c;
							}
						}

						.bg_ecf1cf {
							background-color: #ecf1cf;
						}

						.bg_dbfbf8 {
							background-color: #dbfbf8;
						}

						.bg_fbeffd {
							background-color: #fbeffd;
						}

						.bg_aceffd {
							background-color: #aceffd;
						}
					}



				}
			}

			.content-right {
				border: 2px solid #fff;
				border-radius: 10px;
				background-color: #e0eafe;
				margin-bottom: 20px;
				box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.2);


				.header-tit {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					color: #353535;
					font-size: 24px;
					padding-bottom: 10px;
					border-bottom: 2px solid #fff;
					margin: 11px 22px;

					.left {
						.sign {
							display: inline-block;
							background-color: #2799fc;
							width: 6px;
							height: 6px;
							border-radius: 50%;
							margin-right: 10px;
						}
					}

					.tit {
						font-size: 16px;
						color: #000;
					}
				}

				.sales-statistics-box {
					padding: 0 22px 11px;

					.price-box {
						margin: 16px 0;
						color: #3374fc;

						span {
							font-size: 36px;
						}

						span:last-child {
							color: #000;
							font-size: 18px;
							margin-left: 10px;
						}
					}

					.data-box {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						div {
							display: flex;
							flex-direction: row;

							p {
								font-size: 18px;
								margin-right: 6px;
							}

							span {
								color: #3374fc;
								padding: 4px 6px;
								border: solid 1px #3374fc;
								border-radius: 4px;
							}
						}
					}
				}

				.sales-statistics-tit {
					background-color: #fff;
					padding: 6px 22px;
				}

				.user-data-box {
					margin: 18px 22px;

					.user-data-list {
						display: flex;
						flex-direction: row;
						margin-bottom: 10px;

						span {
							margin-right: 10px;
						}

						.el-progress {
							width: 90%;
						}
					}
				}
			}


		}
	}
</style>