<template>
	<div>
		<div class="container">
			<div class="handle-box" style="display: flex;flex-direction: row;">
				<el-select size="small" v-if="isAdmin" @change="getData" v-model="query.agentId" filterable clearable
					placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" placeholder="请输入号码"
					style="width: 140px;margin-right: 10px;" clearable>
				</el-input>
				<el-input size="small" v-model="query.batchNo" placeholder="请输入批次号"
					style="width: 140px;margin-right: 10px;" clearable></el-input>
				<el-select size="small" style="width: 120px" v-model="query.status" filterable clearable
					placeholder="状态" class="handle-select mr10">
					<el-option label="全部" value="-1"></el-option>
					<el-option label="已查询" value="1"></el-option>
					<el-option label="查询中" value="2"></el-option>
					<el-option label="未查询" value="0"></el-option>
				</el-select>
				<el-select size="small" style="width: 120px" v-model="query.marker" filterable clearable
					placeholder="是否标记" class="handle-select mr10">
					<el-option label="全部" value="0"></el-option>
					<el-option label="已标记" value="1"></el-option>
					<el-option label="未标记" value="2"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-zoom-in"
					@click="addDialog">查询标记</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-circle-plus-outline"
					@click="purchaseDialog" v-if="!isAdmin">购买次数</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" @click="showAdminAdd"
					v-if="isAdmin">添加次数</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" @click="showAdminJumpQueue"
					v-if="isAdmin">插队</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-download"
					@click="getExport">导出</el-button>
				<div style='font-size: 16px;margin-left: 16px;line-height: 40px;'>剩余次数：{{customData}}次</div>
			</div>
			<el-row>
				<el-col :span="24">
					<div id="ai-tab">
						<el-table :data="tableData" border class="table AI-tab-box" ref="multipleTable"
							header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
							:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
							highlight-current-row height="620">
							<el-table-column prop="phone" label="号码" align="center" width="150"></el-table-column>
							<el-table-column prop="batchNo" label="批次号" align="center"></el-table-column>

							<el-table-column prop="status" label="状态" align="center">
								<template #default="scope">
									<el-tag type="danger" size="small" v-if="scope.row.status == '未查询'">未查询</el-tag>
									<el-tag type="success" size="small" v-if="scope.row.status == '已查询'">已查询</el-tag>
									<el-tag size="small" v-if="scope.row.status == '查询中'">查询中</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="baidu" label="百度" align="center">
								<template #default="scope">
									<el-tooltip class="item" effect="dark" v-if="scope.row.baidu == 1"
										:content="`${scope.row.baiduInfo.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="success" size="small" v-if="scope.row.baidu == 0">未标记</el-tag>
									<el-tag type="warning" size="small" v-if="scope.row.baidu == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="ws360" label="360" align="center">
								<template #default="scope">
									<el-tag type="success" size="small" v-if="scope.row.ws360 == 0">未标记</el-tag>
									<el-tooltip class="item" effect="dark" v-if="scope.row.ws360 == 1"
										:content="`${scope.row.ws360Info.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="warning" size="small" v-if="scope.row.ws360 == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="sougou" label="搜狗" align="center">
								<template #default="scope">
									<el-tag type="success" size="small" v-if="scope.row.sougou == 0">未标记</el-tag>
									<el-tooltip class="item" effect="dark" v-if="scope.row.sougou == 1"
										:content="`${scope.row.sougouInfo.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="warning" size="small" v-if="scope.row.sougou == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="telephoneState" label="电话邦" align="center">
								<template #default="scope">
									<el-tag type="success" size="small"
										v-if="scope.row.telephoneState == 0">未标记</el-tag>
									<el-tooltip class="item" effect="dark" v-if="scope.row.telephoneState == 1"
										:content="`${scope.row.telephoneStateInfo.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="warning" size="small"
										v-if="scope.row.telephoneState == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="teddyBear" label="泰迪熊" align="center">
								<template #default="scope">
									<el-tag type="success" size="small" v-if="scope.row.teddyBear == 0">未标记</el-tag>
									<el-tooltip class="item" effect="dark" v-if="scope.row.teddyBear == 1"
										:content="`${scope.row.teddyBearInfo.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="warning" size="small" v-if="scope.row.teddyBear == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="tencent" label="腾讯" align="center">
								<template #default="scope">
									<el-tooltip class="item" effect="dark" v-if="scope.row.tencent == 1"
										:content="`${scope.row.tencentInfo.markerInfo || ''}`" placement="top">
										<el-tag type="danger" size="small">已标记</el-tag>
									</el-tooltip>
									<el-tag type="success" size="small" v-if="scope.row.tencent == 0">未标记</el-tag>
									<el-tag type="warning" size="small" v-if="scope.row.tencent == -1">查询异常</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="createdAt" label="添加时间" align="center" width="155"></el-table-column>
							<el-table-column prop="updatedAt" label="查询时间" align="center" width="155"></el-table-column>
							<el-table-column prop="completionTime" label="完成时间" align="center"
								width="155"></el-table-column>
							<el-table-column prop="server" label="服务器" align="center" width="170"></el-table-column>
						</el-table>
						<div class="pagination">
							<el-pagination background layout="total, sizes, prev, pager, next, jumper"
								:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100, 300]"
								:page-size="query.pageSize" :total="pageTotal" @size-change="handleSizeChange"
								@current-change="handlePageChange"></el-pagination>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-dialog title="购买次数" v-model="purchaseVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="purchaseForm" ref="purchaseForm" label-width="100px">
					<el-form-item label="代理积分消耗">
						<div v-if="price">每个查询
							{{price}}元
						</div>
					</el-form-item>
					<el-form-item label="充值类型">
						<el-radio-group v-model="purchaseForm.type">
							<el-radio label="1">固定方案</el-radio>
							<el-radio label="2">自定义数量</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="支付方式">
						<el-radio-group v-model="purchaseForm.pay" @change="typeChange">
							<el-radio v-for="item in payList" :key="item.value" :label="item.value"
								:value="item.value">{{ item.label }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="方案" prop="agentMarkerId" v-if="purchaseForm.type==1">
						<el-select size="small" v-model="purchaseForm.agentMarkerId" clearable placeholder="请选择套餐"
							style="width: 70%;">
							<el-option v-for="item in optionList" :key="item.id"
								:label="item.quantity*price+'元套餐'+'购买'+item.quantity+'次'+'增送'+item.gift+'次'"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="次数" prop="quantity" v-if="purchaseForm.type==2">
						<el-input size="small" v-model="purchaseForm.quantity" placeholder="请输入购买次数"></el-input>
					</el-form-item>
					<el-form-item label="扫码支付" v-show="payUrl">
						<div id="qrcode" ref="qrcode" style="position: relative;"></div>
						<div><img src="../assets/img/wxpay.png" v-if="wxpayUrl" alt=""
								style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
						<div><img src="../assets/img/alipay.png" v-if="alipayUrl" alt=""
								style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" /></div>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" type="primary" :class="[theme]" @click="gettopup"
							:disabled="enabled">生成二维码</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="查询" v-model="addVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="addForm" ref="addForm" label-width="100px">
					<el-form-item label="类型" prop="type">
						<el-radio-group v-model="addForm.type" @change="typeChange">
							<el-radio label="1">输入框批量</el-radio>
							<el-radio label="2">文件批量</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="号码" prop="quantity" v-if="addForm.type==1">
						<el-input size="small" :rows="5" type="textarea" v-model="addForm.phones" auto-complete="off"
							placeholder="一行一个手机号码,一次最多添加500个号码"></el-input>
					</el-form-item>
					<div v-if="addForm.type==2"
						style="display: flex;flex-direction: row;color: red;margin-left: 60px;margin-bottom: 10px;">
						<div style="font-size: 14px;">实例：</div>
						<div style="display: flex;flex-direction: column;">
							<span style="font-size: 14px;">17600000000</span>
							<span style="font-size: 14px;">17601111111</span>
						</div>
					</div>
					<el-form-item label="上传文件" prop="" v-if="addForm.type==2">
						<el-upload ref="upload" action="/api/markers/addQuery" :headers="token" :limit="1"
							:on-success="uploadSuccess" :auto-upload="false" :data="addForm">
							<el-button size="small" type="primary" :class="[theme]"
								style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">请用txt文件，一个号码一行的格式导入</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAddQuery"
							v-if="addForm.type==1">确定</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="addForm.type==2"
							@click="submitUpload">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="添加次数" v-model="AdminAddVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="AdminAddForm" ref="AdminAddForm" label-width="100px">
					<el-form-item label="代理商" prop="quantity">
						<el-select size="small" v-model="AdminAddForm.agentId" filterable clearable placeholder="代理商"
							style="width: 100%;">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="次数" prop="quantity">
						<el-input size="small" v-model="AdminAddForm.quantity" placeholder="请输入购买次数"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAdminAdd">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="插队" v-model="JumpQueueVisible" width="550px" @close="closeDialog()"
				:close-on-click-modal="false">
				<el-form :model="JumpQueueForm" ref="JumpQueueForm" label-width="100px">
					<el-form-item label="批次号" prop="batchNo">
						<el-input size="small" v-model="JumpQueueForm.batchNo" placeholder="请输入批次号"></el-input>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="suJumpQueue">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		getrecord,
		addQuery,
		getAgentMarkerPrice,
		agentOptionList,
		exportMarkers,
		adminAdd,
		topup,
		JumpQueue
	} from '../api/index.js';
	import { queryOrder, getQueueUpInfo } from '../api/tag.js';
	import { fetchAgent } from '../api/agentIndex';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'tagQuery',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				tableData: [],
				agents: [],
				addVisible: false,
				purchaseVisible: false,
				AdminAddVisible: false,
				pageTotal: 0,
				addForm: {
					type: "1",
					phones: '',
					userId: localStorage.getItem('user'),
				},

				query: {
					phone: '',
					status: '-1',
					marker: '0',
					pageIndex: 1,
					pageSize: 20,
					userId: localStorage.getItem('user'),
					agentId: "",
					batchNo: ''
				},
				isAdmin: false,
				isAgent: false,
				customData: 0,
				price: null,
				JumpQueueVisible: false,
				JumpQueueForm: {
					batchNo: ''
				},
				optionList: [],
				AdminAddForm: {
					userId: localStorage.getItem('user'),
					agentId: "",
					quantity: ""
				},
				purchaseForm: {
					type: '1',
					pay: '2',
					quantity: '',
					agentMarkerId: '',
					userId: localStorage.getItem('user'),
				},
				weixinUrl: false,
				url: '',
				total_fee: '',
				qr: '',
				payList: [{
						value: '2',
						label: '微信支付'
					},
					{
						value: '1',
						label: '支付宝支付'
					}
				],
				payUrl: false,
				wxpayUrl: false,
				alipayUrl: false,
				enabled: false,
				payData: '',
				clearInterval: null,
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'agent') {
				this.isAgent = true;
			}
			this.getFetchAgent();
			this.getagentOptionList();
		},

		methods: {

			//方案
			getagentOptionList() {
				agentOptionList(localStorage.getItem('user')).then(res => {
					if (res.code == 200) {
						this.optionList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData()
			},
			//修改代理
			getData() {
				getrecord(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						this.customData = res.customData;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//二维码
			gettopup() {
				this.$refs.qrcode.innerHTML = '';
				this.wxpayUrl = false;
				this.alipayUrl = false;
				topup(this.purchaseForm).then(res => {
					if (res.code == 200) {
						this.payUrl = true;
						this.payData = res.data;
						if (this.purchaseForm.pay == '2') {
							this.enabled = false;
							this.wxpayUrl = true;
							this.url = this.payData.url;
							this.total_fee = this.payData.total_fee;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.url, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						} else if (this.purchaseForm.pay == 1) {
							this.enabled = false;
							this.alipayUrl = true;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.payData.qrCode, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						}
						this.clearInterval = setInterval(res => {
							let data = {
								orderId: this.payData.out_trade_no
							};
							queryOrder(data).then(res => {
								if (res.code == 200) {
									if (res.data == true) {
										this.$message.success('支付成功');
										this.$refs.qrcode.innerHTML = '';
										this.payUrl = false;
										this.$router.go(0);
										clearInterval(this.clearInterval);
									}
								} else {
									this.$message.error(res.message);
								}
							});
						}, 2000);
					}

				});
			},
			//代理
			getFetchAgent() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
					this.getData();
				});
			},
			getAgentMarkerPrices() {
				getAgentMarkerPrice().then(res => {
					if (res.code == 200) {
						this.price = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			addDialog() {
				getQueueUpInfo().then(res => {
					if (res.code == 200) {
						if (res.data == 0) {
							this.addForm.phones = '';
							this.addForm.type = '1';
							this.addVisible = true;
						} else {
							this.$confirm(`当前还有${res.data}个号码还在查询中, 预计还需等待${res.data/10}分钟`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.addForm.phones = '';
								this.addForm.type = '1';
								this.addVisible = true;
							}).catch(() => {
								this.addVisible = false;
							});
						}

					} else {
						this.$message.error(res.message);
					}
				});

			},
			purchaseDialog() {
				this.purchaseForm.number = '';
				this.purchaseVisible = true;
				this.getAgentMarkerPrices();
			},
			closeDialog() {
				this.purchaseForm.type = '1';
				this.purchaseForm.pay = '2';
				this.AdminAddForm.agentId = "";
				this.AdminAddForm.quantity = "";
				this.JumpQueueForm.batchNo = "";
				this.JumpQueueVisible = false;
				this.purchaseVisible = false;
				this.AdminAddVisible = false;
				this.addVisible = false;
				clearInterval(this.clearInterval);
			},
			getAddQuery() {
				addQuery(this.addForm).then(res => {
					if (res.code == 200) {
						this.addVisible = false;
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//插队
			showAdminJumpQueue() {
				this.JumpQueueVisible = true;
			},
			suJumpQueue() {
				let data = {
					userId: localStorage.getItem('user'),
					batchNo: this.JumpQueueForm.batchNo
				}
				JumpQueue(data).then(res => {
					if (res.code == 200) {
						this.JumpQueueVisible = false;
						this.$message.success(res.data);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showAdminAdd() {
				this.AdminAddForm.quantity = '';
				this.AdminAddForm.agentId = '';
				this.AdminAddVisible = true;
			},
			getAdminAdd() {
				adminAdd(this.AdminAddForm).then(res => {
					if (res.code == 200) {
						this.AdminAddVisible = false;
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			//导入上传
			uploadSuccess(success) {
				setTimeout(() => {
					this.$refs.upload.clearFiles();
				}, 2000);
				if (success.code == 200) {
					this.addVisible = false;
					this.$message.success('上传成功');
					setTimeout(() => {
						this.getData();
					}, 3000);
				} else {
					this.$message.error(success.message);
				}
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				console.log(val, '说是历史记录')
				this.query.pageIndex = val;
				this.getData();
			},
			getExport() {
				if (this.query.batchNo) {
					let data = {
						phone: this.query.phone,
						status: this.query.status,
						marker: this.query.marker,
						userId: localStorage.getItem('user'),
						batchNo: this.query.batchNo,
						agentId: this.query.agentId
					};
					exportMarkers(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '标记查询' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				} else {
					this.$message.error('请输入批次号查询');
				}
			},
			typeChange(e) {
				this.$refs.qrcode.innerHTML = '';
				this.purchaseForm.pay = e;
				this.payUrl = false;
				clearInterval(this.clearInterval);
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}
</style>