import request from '../utils/request';
import qs from "qs";


export const findAfterSaleByRole = data => {
	return request.get('/api/afterSale/findAfterSaleByRole?currentUserId=' + data.currentUserId)
};
export const updateAfterSale = param => {
	return request.post('/api/afterSale/updateAfterSale', qs.stringify(param))
};
//下载客户磨板
export const downloadIntentionTemplate = param => {
	return request.post('/api/intentionCustomer/downloadIntentionTemplate', qs.stringify(param), {
		responseType: 'blob'
	})
};
//短信模版   设置企业短信参数
export const companySettings = param => {
	return request.post('/api/sortMsg/companySettings', qs.stringify(param))
};
//短信模版  列表
export const temAList = param => {
	return request.post('/api/sortMsg/temAList', qs.stringify(param))
};
//短信模版  添加
export const temAdd = param => {
	return request.post('/api/sortMsg/temAdd', qs.stringify(param))
};
//短信模版  修改
export const temUpdate = param => {
	return request.post('/api/sortMsg/temUpdate', qs.stringify(param))
};
//短信模版  删除
export const temDelete = param => {
	return request.post('/api/sortMsg/temDelete', qs.stringify(param))
};
//短信模版  查询企业短信参数
export const companySettingsQuery = param => {
	return request.post('/api/sortMsg/companySettingsQuery', qs.stringify(param))
};